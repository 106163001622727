<template>
  <div>
    <BlockItem :size="'1-2'">
      <span style="font-size: 26px;">
        {{$t('analytics_alert')}}.
      </span>
    </BlockItem>

    <!-- <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <BlockItem :size="'1-2'">
      <span style="font-size: 16px; display: inline-block; margin-right: 10px">
        Date range:
      </span>
      <date-picker
        v-model="dateFilter"
        v-on:change="loadAnalytics()"
        range
      ></date-picker>
    </BlockItem>
    <BlockItem :size="'1-2'">
      <BlockInfoSelect
        title="Country:"
        :selected="countryCodes[0]"
        :values="countryCodes"
        @change-value="changeCountry"
      />
    </BlockItem>

    <div id="jstree"></div>

    <div>
      <CRow>
        <CCol>
          <ListAnalytics v-if="!loading" v-bind:tableItems="listAnalytics" />
        </CCol>
      </CRow>
    </div>

    <div>
      <CButton
        color="success"
        size="lg"
        class="versions-button"
        @click="exportXlsx()"
        >Export XLSX</CButton
      >
      <br />
      <br />
    </div> -->
  </div>
</template>



<script>
import ListAnalytics from "../components/ListAnalytics.vue";
import jstree from "jstree";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import BlockInfoSelect from "../components/BlockInfoSelect";
import BlockItem from "../components/BlockItem";
export default {
  name: "Analytics",
  components: {
    ListAnalytics,
    BlockInfoSelect,
    BlockItem,
    DatePicker,
  },
  data() {
    return {
      listAnalytics: [],
      loading: false,
      country: null,
      countryCodes: [],
      dateFilter: [],
    };
  },
  computed: {},
  async mounted() {
  return;
    var self = this;
    self.country = localStorage.getItem("userCountry")
      ? JSON.parse(localStorage.getItem("userCountry"))
      : "";
    try {
      self.countryCodes = [self.country];
      const res = await self.$http.get("apiConsole/console/getCountries");
      var countries = res.data.countries;
      self.countryCodes = [];
      countries.map((e) => {
        self.countryCodes.push(e.code);
      });
      self.changeCountry(self.countryCodes[0]);
    } catch (e) {
      if (e.response) {
        const res = e.response;
        console.log(res.data.message);
      }
      console.error(e);
    }

    self.loading = false;
    self.dateFilter = [
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(),
    ];

    this.loadAnalytics();
  },
  methods: {
    async loadAnalytics() {
      var self = this;
      if (self.dateFilter[0] && self.dateFilter[1]) {
        self.loading = true;
        var date1 = new Date(
          self.dateFilter[0].getTime() -
            self.dateFilter[0].getTimezoneOffset() * 60 * 1000
        );
        var date2 = new Date(
          self.dateFilter[1].getTime() -
            self.dateFilter[1].getTimezoneOffset() * 60 * 1000
        );

        const res = await self.$http.get(
          `apiConsole/console/analytics/${
            self.country
          }/${date1.getTime()}/${date2.getTime()}`
        );
        self.loadJtree(res.data.analytics);
        self.listAnalytics = res.data.drivers
        self.loading = false;
      }
    },
    async loadJtree(data) {
      var self = this;
      self.$$("#jstree").jstree("destroy");
      self
        .$$("#jstree")
        .jstree({
          core: {
            themes: {
              icons: false,
            },
            data: [],
          },
        })
        .bind("refresh.jstree", function (e, data) {
          self.$$(this).jstree("open_all");
        });
      self.$$("#jstree").jstree(true).settings.core.data = data[0];
      self.$$("#jstree").jstree(true).refresh();
    },
    changeCountry(country) {
      var self = this;
      self.country = country;
      self.loadAnalytics();
    },
    async exportXlsx() {
      const self = this;
      var date1 = new Date(
        self.dateFilter[0].getTime() -
          self.dateFilter[0].getTimezoneOffset() * 60 * 1000
      );
      var date2 = new Date(
        self.dateFilter[1].getTime() -
          self.dateFilter[1].getTimezoneOffset() * 60 * 1000
      );
      const response = await self.$http(
        {
          url: `apiConsole/console/exportanalytics/${
            self.country
          }/${date1.getTime()}/${date2.getTime()}`,
          method: "GET",
          responseType: "blob",
        } // important
      );

      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `MyTruck_Analytics_${self.country}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        // BLOB FOR EXPLORER 11
        const url = window.navigator.msSaveOrOpenBlob(
          new Blob([response.data]),
          `MyTruck_Analytics_${self.country}.xlsx`
        );
      }
    },
  },
};
</script>
<style>
@import "~jstree/dist/themes/default/style.css";

.jstree-default a.jstree-anchor {
  color: black !important;
}

.jstree-default a {
  white-space: normal !important;
  height: auto;
}

.jstree-anchor {
  height: auto !important;
}

.jstree-default li > ins {
  vertical-align: top;
}

.jstree-leaf {
  height: auto;
}

.jstree-leaf a {
  height: auto !important;
}

.jstree-additional-info {
  display: inline-block;
  vertical-align: inherit;
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
