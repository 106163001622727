<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          table-filter
          items-per-page-select
        >
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
const moment = require("moment");
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
export default {
  name: "ListAnalytics",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  props: {
    tableItems: Array,
    countryCodes: Array,
    countryChossed: String,
  },
  computed: {
    _country() {
      return this.countryA;
    },
  },
  data() {
    return {
      layout: "list",
      tableTitle: "Analytics",
      tableFields: [
        { key: "name", label: "Driver" },
        { key: "email", label: "Email" },
        { key: "license", label: "License" },
        { key: "sapId", label: "Sap Id" },
        { key: "deliveries_quantity", label: "Total Deliveries" },
        { key: "quantity", label: "Total Quantity (m³)" },
        { key: "distance", label: "Total Distance (km)" },
      ],

      listStatus: [],
    };
  },
  async created() {
    const self = this;
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}

.buttons {
  display: flex;
}
</style>